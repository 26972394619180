<template>
  <v-container fluid>
    <div class="header">
      <div class="combo_section">
        <v-select dense outlined  rounded class="combobox ma-1"
          id="farm" 
          ref="farm" 
          label="농장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @focus="comboEsgFarms()"
          >
          <template v-slot:item="slotProps" >
            {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.farmNo}}</span>
          </template>
        </v-select>
        
        <v-menu
          v-model="menu"
          :nudge-right="10"
          nudge-bottom="40"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined class="calendar shrink mt-1 ml-2 mb-1 mr-1"
              id="to_date" 
              ref="to_date" 
              v-model="to_date"
              label="기준월"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title 
            locale="ko-KR"
            v-model="to_date"
            type="month"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>
        </div>

        <div class="combo_section radio mb-3" style="margin-top:-30px;">
          <div class="radio_content mb_radio mt-3">
            <div style="margin-top:-10px">
              <v-radio-group dense outlined row 
                id="val" 
                ref="val" 
                v-model="val"
              >
                <v-radio label="연간 배출량" value="year" color="#3a4f3f"/>
                <!-- <v-radio label="출하 KG 당 배출량" value="kg" color="#3a4f3f"/>  -->
              </v-radio-group>
            </div>
        </div>
        <v-btn @click="chartEsg()" rounded class="normal_btn mt-4 ml-2"
        :loading="loading">
          예상하기
        </v-btn>
      </div>

      <div class="simaultion_content">
        <table class="table" >
          <tr>
            <th class="th_title">변수</th>
            <th class="th_title">현재</th>
            <th class="th_title">변경</th>
          </tr>
          <tr>
            <th>연간두수</th>
            <td> <span class="td_info">{{ this.YearpigCount }} </span>두</td>
            <td> 
              <input type="number" name="number" size="18" style="width:90px;" v-model.number="pigCount">두
            </td>
          </tr>
          <tr>
            <th rowspan='2'>분뇨처리방식</th>
            <td>
              <div style="display:flex;">
                <div class="td_font" style="width:150px;">액상 슬러리 보관 (4개월 ↑)</div>
                <div class="percent mt-1"> 30 % </div>
              </div>
            </td>
            <td class="td_text_size">
              <div style="display:flex;">
                <div  class="td_font" style="width:150px;">액상 슬러리 보관 (4개월 ↑)</div>
                <input type="number" name="number" size="18" style="width:40px;" v-model.number="change_1"> %
              </div>
              <!-- <v-select dense style="height:30px !important;"
                :items="items"
                :menu-props="{ top: false, offsetY: true }"
              /> -->
            </td>
            
          </tr>
          <tr>
            <td>
              <div style="display:flex;">
                <div  class="td_font" style="width:150px;">혐기소화조 (최고등급)</div>
                <div class="percent mt-1"> 70 % </div>
              </div>
            </td>
            <td>
              <div style="display:flex;">
                <div  class="td_font" style="width:150px;">혐기 소화조 (최고등급)</div>
                <input type="number" name="number" size="18" style="width:40px;" v-model.number="change_2"> %
              </div>
              <!-- <v-select dense style="height:30px !important;"
                :items="items"
                :menu-props="{ top: false, offsetY: true }"
              /> -->
            </td>
            
          </tr>
          <!-- <tr>
            <th>에너지사용량</th>
            <td> </td>
            <td>
              <input type="text" name="text" size="18" style="width:90px;" v-model="watt">kWh
            </td>
          </tr> -->
        </table>
      </div>

      <div class="simaultion_chart" v-if="this.val=='year'">
        <div class="chart_container">
          <div class="chart_title">총합</div>
          <Bar
            id="Esgchart"
            :chart-options="options"
            :chart-data="emissionData"
            style="position: relative; height:250px; width:90%; margin: 0 auto; "
          />
        </div>

        <div class="chart_container">
          <div class="chart_title">메탄</div>
          <Bar
            id="Esgchart"
            :chart-options="Gasoptions"
            :chart-data="ch4Data"
            style="position: relative; height:250px; width:90%; margin: 0 auto; "
          />
        </div>

        <div class="chart_container">
          <div class="chart_title">아산화질소</div>
          <Bar
            id="Esgchart"
            :chart-options="Gasoptions"
            :chart-data="n2oData"
            style="position: relative; height:250px; width:90%; margin: 0 auto; "
          />
        </div>
      </div>

      <div class="simaultion_chart" v-else>
        <div class="chart_container">
          <div class="chart_title">총합</div>
          <Bar
            id="Esgchart"
            :chart-options="options"
            :chart-data="emissionData"
            style="position: relative; height:250px; width:90%; margin: 0 auto; "
          />
        </div>

      </div>


      
    </div>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar }  from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  name: 'SimulationEsg',
  components: { Bar },
  async created(){
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    await this.comboEsgFarms();

    let cookie_info = VueCookies.get("esg_simulation");

    if (!this.isNull(this.$route.params)) {
      this.farm = this.$route.params.farm;
    }else if (cookie_info) {
      this.farm = cookie_info.farm || {};
    } else {
      this.farm = this.farmItems[0];
    }
    this.monitoringEsgValueOfFarm();
    
    let cdate = new Date();
    cdate.setMonth(cdate.getMonth());
    this.to_date= dateUtil.format(cdate, "yyyy-MM");

  },
  watch:{
    farm(){
      this.monitoringEsgValueOfFarm();
    },
    // val(newVal, oldVal) {
    //     if (newVal !== oldVal) this.chartEsg();
    // }
  },
  data(){
    return{
      loading:false,
      farm : {},
      farmItems : [],
      val : 'year',

      showChart:false,
      menu:false,

      esgData:{},
      YearpigCount:'',
      YearCh4:'',
      YearN2o:'',

      // 변경값
      changeEF:'',
      changeNcdg:'',

      pigCount:'',
      change_1:'',
      change_2:'',

      changePoint1:'',
      changePoint2:'',

      simulintestinal:'',
      simulCh4:'',
      simulEnergy:'',

      simulN2o:'',

      simulCh4Total:'',

      emissionData:{},
      ch4Data:{},
      n2oData:{},
      
      to_date: "",
      
      Gasoptions:{
        responsive:true,
        maxBarThickness: 45, // 막대 굵기
        maintainAspectRatio: false,
        plugins:{
          datalabels: {
            display:false,
          },
          legend:{
            display:false,
          }
        },
        scales: {
            x: {
                title: {
                    display: true,
                },
                grid: { 
                  drawTicks: true, 
                  color: '#E2E2E230' 
                },
            },
            y: {
                beginAtZero: true,
            }
        }
      },
      options: {
        // responsive: false,
        maintainAspectRatio: false,
        maxBarThickness: 55, // 막대 굵기
        interaction: {
          mode: 'index',
        },
        plugins: {
          datalabels: {
            display:false,
          },
          legend: {
            position: 'bottom',
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: { 
              display: false,
              drawTicks: true, 
              tickLength: 4,
              color: '#E2E2E230' 
            },
          },
          y: {
            stacked: true,
          }
        }
      },

    }
  },
  methods:{
    async monitoringEsgValueOfFarm(){
    this.loading = true;

    await Apis.monitoringEsgValueOfFarm2({
      farmNo:this.farm.farmNo,
      stand_date: dateUtil.format(new Date(), "yyyy-MM-01"), 
      month:12,
      },(res) => {
        this.loading = false;
        this.esgData = res.data;

        this.YearpigCount = res.data.sel.사육두수.toFixed(0);

        this.YearCh4 = this.esgData.sel.intestinal.ch4.local + this.esgData.sel.excreta.ch4.local+this.esgData.sel.energy.ch4.local;
        this.YearN2o = this.esgData.sel.excreta.n2o.local+this.esgData.sel.energy.n2o.local;

        this.chartEsg();
        this.$store.commit("resMessage",res.message);
        let cookie_info = { farm: this.farm};
        VueCookies.set("esg_simulation" ,cookie_info,"30d");
      }).catch( (err) =>{
        console.log("monitoringEsgValueOfFarm2 API 호출 오류",err)
      })
    },

    chartEsg(){
      this.showChart=true;

      this.simulEnergy = 8213.40714;

      // 분뇨처리 메탄
      this.changePoint1 = Number(this.change_1) / 100
      this.changePoint2 = Number(this.change_2) / 100

      this.changeEF = 0.3*365*(0.45*0.67*(0.29*this.changePoint1 +0.01*this.changePoint2))
      this.simulCh4 = this.pigCount * this.changeEF;

      //분뇨처리 아산화질소
      this.changeNcdg = this.pigCount * 2.61 * 365 * (0.2/0.8) * 0.01;

      this.simulN2o = ((this.pigCount*10.97*this.changePoint1*0.005)+((this.pigCount*10.97*this.changePoint2+this.changeNcdg)*0.0006))*44/28

      if(this.val == 'year'){
        this.emissionData = this.YearAllData();
        this.ch4Data = this.YearCh4Data();
        this.n2oData = this.YearN2oData();
      }
    },
    YearAllData(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['현재', '변경'],
        // data : [현재 값, 산출된 값]
        datasets: [
            {
              label: '메탄', 
              data:[(this.YearCh4 * 28) + this.esgData.sel.energy.ch4.local , ((this.YearCh4 * 28) + this.esgData.sel.energy.ch4.local) * (this.pigCount/this.YearpigCount)  ], 
              backgroundColor: 'rgba(21, 52, 72, 0.6)',
              borderWidth: 1 
            },
            {
              label: '아산화질소', 
              data:[this.YearN2o * 256, this.YearN2o * 256 * (this.pigCount/this.YearpigCount) ], 
              backgroundColor: 'rgba(60, 91, 111, 0.6)', 
              borderWidth: 1 
            },
          ]
      }
    },
    YearCh4Data(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['장내발효', '분뇨메탄', '에너지', '총배출량'],
        datasets: [
          {
              label: '',
              data: [this.esgData.sel.intestinal.ch4.local, this.esgData.sel.excreta.ch4.local, this.esgData.sel.energy.ch4.local, this.YearCh4 ],
              backgroundColor: [
                  'rgb(181, 193, 142, 0.5)',
              ],
              borderWidth: 1
          },
          // 변경 후 산출된 값
          {
              label: '',
              data: [ this.esgData.sel.intestinal.ch4.local * (this.pigCount/this.YearpigCount) , this.simulCh4 , this.esgData.sel.energy.ch4.local * (this.pigCount/this.YearpigCount)  , this.YearCh4 * (this.pigCount/this.YearpigCount) ],
              backgroundColor: [
                  'rgb(222, 172, 128, 0.5)',
              ],
              borderWidth: 1
          }
        ]
      }
    },
    YearN2oData(){
      return{
        plugins: [ChartDataLabels], 
        labels: [ '분뇨처리','에너지','총배출량'],
        datasets: [
            {
                data: [this.esgData.sel.excreta.n2o.local,this.esgData.sel.energy.n2o.local, this.YearN2o ],
                backgroundColor: [
                    'rgb(26, 77, 46, 0.5)',
                ],
            },
            // 변경 후 산출된 값
            {
                data: [ this.simulN2o, this.esgData.sel.energy.n2o.local * (this.pigCount/this.YearpigCount) ,this.simulN2o ],
                backgroundColor: [
                    'rgb(232, 223, 202, 0.5)',
                ],
                borderWidth: 1
            }
        ]
      }

    },
    async comboEsgFarms() {
      await Apis.comboEsgFarms({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              code: res.data[i].farm.code,
              name: res.data[i].farm.name,
              farmNo: res.data[i].farm.farmNo
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
  }
}
</script>

<style lang="scss" scoped>
input , .percent{
  background-color: #e3efe6c2;
  border-radius: 10px;
  text-align:center
}
input:focus {
  outline: 2px solid #3a4f3f;
} 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.percent{
  width:50px;
  color:#3a4f3f;
  font-weight:500;
}
::v-deep .v-label{
  letter-spacing: -1.5px;
}
::v-deep .v-btn--active{
  background-color:#3a4f3f !important;
}
::v-deep .v-date-picker-table__current {
  color:#3a4f3f !important;
}
.normal_btn{
  border-radius: 50px;
  color:white !important;
  font-size:13px;
  font-weight: bold !important;
  letter-spacing: -0.75px;
  background-color: #3a4f3f !important;
}
.header{
  border-radius: 10px;
}
.combo_section{
  display:flex;
  justify-content: center;
}
.combobox{
  max-width:200px;
}
.calendar{
  max-width:90px;
  border-radius: 50px !important;
}
.items{
  font-size:13px;
}
.radio_content{
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  height:45px;
}
.simaultion_content{
  display:flex;
  justify-content: center;
}
.table th{
  background:#47604d;
  color:white;
  padding:10px;
  text-align:left;
  width:180px;
  text-align:center;
  border-radius: 15px;
}
.table td{
  border:1px solid #3a4f3f;
  color:#3a4f3f;
  padding:10px;
  text-align: center;
  border-radius: 15px;
  // font-size:10px;
}
.td_font{
  font-size:15px;
  letter-spacing: -1px;
  line-height:30px;
}
.th_title{
  text-align:center !important;
}
.td_info{
  width:100px !important;
}
.simaultion_chart{
  display:flex;
  margin-top:30px;
}
.chart_container{
  width:700px;
}
.chart_title{
  padding:10px;
  font-weight:600;
  letter-spacing: -1.5px;
}
.chart_container{
  padding:10px;
  margin:5px;
  border:1px solid #e4e4e4;
  border-radius: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  background-color:#ffffff83;
}
</style>